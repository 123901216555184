import { Box, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { BackTo } from '../../components';

// TODO: Finish when backend add endpoint to get location by worker
export const EmployeeLocationPage = () => {
  const history = useHistory();
  const theme = useTheme();
  const goBack = () => {
    history.goBack();
  };

  return (
    <Box borderTop={2} borderColor={theme.palette.divider}>
      <BackTo backTo={{ text: 'Locations & Shifts', back: goBack }} />
    </Box>
  );
};
