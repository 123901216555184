import { BiBuildings } from 'react-icons/bi';
import { FiBriefcase, FiCreditCard, FiLayers, FiUser, FiUsers } from 'react-icons/fi';
import { HiOutlineViewGrid } from 'react-icons/hi';
import { MdAccessTime, MdOutlinePayments } from 'react-icons/md';
import { RiTeamLine } from 'react-icons/ri';
import { WiTime4 } from 'react-icons/wi';
import { FiCpu } from 'react-icons/fi';
import { LuFileClock } from 'react-icons/lu';

import { INTERFACE_PERMISSIONS, NavItem } from '@vyce/core/src/types';

export enum ROUTES_WITHOUT_NAVIGATION {
  EMAIL_NOTIFICATIONS = '/email-notifications',
  MAINTENANCE = '/maintenance',
}

export const EMPLOYER_NAV_ITEMS: NavItem[] = [
  { name: 'Dashboard', icon: HiOutlineViewGrid, link: '/dashboard' },
  {
    name: 'Hiring',
    icon: FiBriefcase,
    link: '/hiring/live',
  },
  {
    name: 'Teams',
    icon: FiUsers,
    link: '/teams/dashboard',
  },
  {
    name: 'Projects',
    icon: FiLayers,
    link: '/projects',
  },
  {
    name: 'Time & Attendance',
    icon: WiTime4,
    link: '/time/dashboard',
  },
  {
    name: 'Payroll',
    icon: MdOutlinePayments,
    link: '/payroll/dashboard',
  },
  {
    name: 'Vyce AI',
    icon: FiCpu,
    link: '/vyce-ai',
  },
  {
    name: 'User Management',
    icon: RiTeamLine,
    link: '/user-management/payroll',
    permissions: [INTERFACE_PERMISSIONS.STAFF_MANAGEMENT],
    isBottom: true,
  },
  {
    name: 'Subscription & Billing',
    icon: FiCreditCard,
    link: '/subscriptions',
    permissions: [INTERFACE_PERMISSIONS.BILLING],
    isBottom: true,
  },
  {
    name: 'My Companies',
    icon: BiBuildings,
    link: '/companies',
    isBottom: true,
  },
  { name: 'Profile', icon: FiUser, link: '/profile', isBottom: true },
];

export const EMPLOYEE_NAV_ITEMS: NavItem[] = [
  { name: 'Dashboard', icon: HiOutlineViewGrid, link: '/dashboard' },
  { name: 'Time Logs', icon: MdAccessTime, link: '/timelogs/logs' },
  { name: 'Timesheets', icon: LuFileClock, link: '/employee-payroll' },
  { name: 'Payments', icon: MdOutlinePayments, link: '/payments' },
  {
    name: 'Vyce AI',
    icon: FiCpu,
    link: '/vyce-ai',
  },
  { name: 'Profile', icon: FiUser, link: '/profile', isBottom: true },
];

export const DEFAULT_NAV_ITEMS: NavItem[] = [
  {
    name: 'Dashboard',
    link: '/dashboard',
    icon: HiOutlineViewGrid,
    disabled: true,
  },

  {
    name: 'Profile',
    link: '/profile',
    icon: FiUser,
    isBottom: true,
    disabled: true,
  },
];
