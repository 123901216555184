import { TabItem } from '@vyce/core/src/types';

export const EMPLOYEE_TIME_AND_ATTENDANCE_TABS: TabItem[] = [
  {
    label: 'Time Logs',
    link: '/timelogs/logs',
  },
  {
    label: 'Locations & Shifts',
    link: '/timelogs/locations-shifts',
  },
];
