import { Box, Typography } from '@material-ui/core';

import { DetailsIcon } from '../../../components';
import { ShiftSchedule } from '../../../types';
import { getWeekHoursPopupData } from '../../../views/time/LocationPage/LocationPreview';

interface Props {
  schedule?: ShiftSchedule;
  type: 'Regular' | 'Overtime';
}

export const TableShiftType = ({ schedule, type }: Props) => {
  return (
    <Box display="flex" gridGap={8}>
      <Typography style={{ flex: 1 }} variant="subtitle2">
        {type}
      </Typography>
      <DetailsIcon size="18px" content={getWeekHoursPopupData(schedule, undefined, true)} />
    </Box>
  );
};
