import { useCallback, useContext, useEffect, useState } from 'react';

import { getEmployeeLocationsRequest, getTimeWorkerRequest } from '../../../api/time';
import { NotificationContext } from '../../../contexts/notificationContext';
import { TimeAndAttendanceLocation, Site, TimeWorkerShift, Shift } from '../../../types';
import { siteToLocation } from '../../timeModule/utils';

export interface WorkerLocation {
  location: TimeAndAttendanceLocation;
  shifts: TimeWorkerShift[];
}

export const useEmployeeLocationsData = (userId: string) => {
  const { handleServerError } = useContext(NotificationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [locations, setLocations] = useState<WorkerLocation[]>([]);
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [search, setSearch] = useState<string>('');

  const [total, setTotal] = useState<number>(0);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getEmployeeLocationsRequest({
        userId,
        payload: {
          limit: 1000,
          offset: 0,
          order_by: [{ field: 'updated_at', desc: true }],
          only_active: true,
          only_inactive: false,
          show_deleted: false,
          substring: search,
        },
      });
      const resShifts = await getTimeWorkerRequest(userId);
      const workerShifts = resShifts.data.shifts;
      const locations: TimeAndAttendanceLocation[] = res.data.items.map((item: Site) =>
        siteToLocation(item)
      );
      const shifts: Shift[] = [];
      locations.forEach(location => {
        if (location.shifts) {
          shifts.push(...location.shifts);
        }
      });
      setShifts(shifts);
      const locationsWithShifts = locations.map(location => ({
        location,
        shifts: workerShifts.filter((shift: TimeWorkerShift) => shift.site.site_id === location.uuid),
      }));
      setLocations(locationsWithShifts);
      setTotal(res.data.total);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [userId, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    locations,
    total,
    loading,
    shifts,
    setSearch,
  };
};
