import { Box, Typography } from '@material-ui/core';
import { AppCustomTable, AppIconButton } from '@vyce/core/src/components';
import { RiEyeLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

import { Props } from './types';
import { useEmployeeLocationsData } from './hooks/useEmployeeLocationsData';
import { AppCustomTableColumn, Shift, TimeWorkerShift } from '../../types';
import { TableShiftType } from './components/TableShiftType';

export const EmployeeLocations: React.FC<Props> = ({ userId }) => {
  const { locations, shifts } = useEmployeeLocationsData(userId);
  const history = useHistory();

  const getShift = (shiftId: string): Shift | undefined => shifts.find(s => s.uuid === shiftId);

  // TODO: Finish when backend add endpoint to get location by worker
  // const goToLocationPreview = (locationId: string) => {
  //   history.push(`/timelogs/locations-shifts/${locationId}`);
  // };

  const columns: AppCustomTableColumn[] = [
    {
      name: 'name',
      title: 'Location',
      flex: 1,
      renderCell: row => (
        <Box display="flex" gridGap={8} paddingRight={4}>
          <Typography style={{ flex: 1 }} variant="subtitle2">
            {row.location.name}
          </Typography>

          {/* <AppIconButton size="small" onClick={() => goToLocationPreview(row.location.uuid)}>
            <RiEyeLine />
          </AppIconButton> */}
        </Box>
      ),
    },
    {
      name: 'shifts',
      title: 'Shifts',
      renderCell: row => {
        return (
          <Box display="flex" gridGap={8} flexDirection="column">
            {row.shifts.map((shift: TimeWorkerShift) => (
              <Box height={52} key={shift.shift.shift_id} display="flex" gridGap={8}>
                <Typography style={{ flex: 1 }} variant="subtitle2">
                  {shift.shift.name}
                </Typography>
              </Box>
            ))}
          </Box>
        );
      },
      flex: 0.8,
    },
    {
      name: 'shifts',
      title: 'Type',
      width: '200px',
      renderCell: row => {
        return (
          <Box display="flex" gridGap={8} flexDirection="column">
            {row.shifts.map((shift: TimeWorkerShift) => (
              <Box
                key={shift.shift.shift_id}
                display="flex"
                gridGap={8}
                flexDirection="column"
                paddingRight={4}>
                <TableShiftType
                  type="Regular"
                  schedule={getShift(shift.shift.shift_id)?.regular?.schedule}
                />

                <TableShiftType
                  type="Overtime"
                  schedule={getShift(shift.shift.shift_id)?.overtime?.schedule}
                />
              </Box>
            ))}
          </Box>
        );
      },
      flex: 0.2,
    },
    {
      name: 'rate',
      title: 'Rate (£)',
      flex: 0.4,
      renderCell: row => {
        return (
          <Box display="flex" gridGap={8} flexDirection="column">
            {row.shifts.map((shift: TimeWorkerShift) => (
              <Box key={shift.shift.shift_id} display="flex" gridGap={8} flexDirection="column">
                <Typography variant="subtitle2">{shift.current_rates?.basic?.amount || '-'} £</Typography>
                <Typography variant="subtitle2">{shift.current_rates?.overtime?.amount || '-'} £</Typography>
              </Box>
            ))}
          </Box>
        );
      },
    },
    {
      name: 'unit',
      title: 'Per what?',
      flex: 0.4,
      renderCell: row => {
        return (
          <Box display="flex" gridGap={8} flexDirection="column">
            {row.shifts.map((shift: TimeWorkerShift) => (
              <Box key={shift.shift.shift_id} display="flex" gridGap={8} flexDirection="column">
                <Typography variant="subtitle2">{shift.current_rates?.basic?.unit || '-'}</Typography>
                <Typography variant="subtitle2">{shift.current_rates?.overtime?.unit || '-'}</Typography>
              </Box>
            ))}
          </Box>
        );
      },
    },
  ];

  return (
    <Box display="flex" flexDirection="column" gridGap={24}>
      <AppCustomTable columns={columns} data={locations || []} />
    </Box>
  );
};
